<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="isCreated ? '新增' : '编辑'"
    :visible.sync="show"
    width="800px"
    class="add-account"
    v-loading="loading"
  >
    <el-form
      size="small"
      :model="params"
      ref="form"
      label-width="100px"
      :rules="rules"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="产品序列号" prop="masterSn">
            <el-input v-model="params.masterSn" placeholder="请输入主机sn" />
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">
          <el-form-item label="产品名称">
            <el-input
              v-model="params.masterName"
              placeholder="请输入产品名称"
            />
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="配件类型" prop="partsTypeId">
            <el-select
              style="width: 100%"
              v-model="params.partsTypeId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in partsTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="配件型号" prop="fittingMode">
            <SelectGoods
              searchKey="spec"
              :spec.sync="params.fittingMode"
              :code.sync="params.fittingCode"
              :name.sync="params.fittingName"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="配件料号" prop="fittingCode">
            <SelectGoods
              searchKey="code"
              :spec.sync="params.fittingMode"
              :code.sync="params.fittingCode"
              :name.sync="params.fittingName"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="配件名称" prop="fittingName">
            <SelectGoods
              searchKey="name"
              :spec.sync="params.fittingMode"
              :code.sync="params.fittingCode"
              :name.sync="params.fittingName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="配件序列号">
            <el-input
              v-model="params.fittingSn"
              placeholder="请输入配件序列号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="保修日期" prop="warrantyDate">
            <el-date-picker
              style="width: 100%"
              v-model="params.warrantyDate"
              placeholder="请选择保修日期"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出库日期" prop="outboundDate">
            <el-date-picker
              style="width: 100%"
              v-model="params.outboundDate"
              placeholder="请选择出库日期"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确定</el-button>
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: {
    partsTypeList: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    const rules = {
      masterSn: [{ required: true, message: '请输入产品序列号' }],
      partsTypeId: [{ required: true, message: '请选择配件类型' }],
      fittingMode: [{ required: true, message: '请选择配件型号' }],
      fittingCode: [{ required: true, message: '请选择配件料号' }],
      fittingName: [{ required: true, message: '请选择配件名称' }]
    }

    return {
      loading: false,
      rules,
      params: {
        fittingMode: '',
        fittingCode: '',
        fittingName: ''
      }
    }
  },
  created() {
    if (!this.isCreated) {
      this.params = { ...this.currentItem }
	  this.params.partsTypeId = this.currentItem.typeId
    }
  },

  methods: {
    async submitForm() {
      console.log(this.params)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            let params = { ...this.params }
            // 编辑时也格式化
            params.warrantyDate = this.$utils.dateFormat(
              new Date(params.warrantyDate),
              'YYYY-mm-dd'
            )
            params.outboundDate = this.$utils.dateFormat(
              new Date(params.outboundDate),
              'YYYY-mm-dd'
            )
            if (this.isCreated) {
              await this.$api.workOrder.insertFittingData(params)
            } else {
              await this.$api.workOrder.updateFittingData(params)
            }

            this.$message.success('操作成功')
            this.$emit('updateList')
            this.show = false
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped></style>
