<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="产品序列号" prop="masterSn">
          <el-input placeholder="请输入" v-model="params.masterSn"></el-input>
        </el-form-item>
        <el-form-item label="配件类型" prop="partsTypeId">
          <el-select
            v-model="params.partsTypeId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in partsTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配件序列号" prop="fittingSn">
          <el-input placeholder="请输入" v-model="params.fittingSn"></el-input>
        </el-form-item>
        <el-form-item label="配件料号" prop="fittingCode">
          <el-input
            placeholder="请输入"
            v-model="params.fittingCode"
          ></el-input>
        </el-form-item>
        <el-form-item label="配件型号" prop="fittingMode">
          <el-input
            placeholder="请输入"
            v-model="params.fittingMode"
          ></el-input>
        </el-form-item>
        <el-form-item label="出库日期" prop="time">
          <el-date-picker
            style="width: 100%"
            type="daterange"
            v-model="params.time"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() > Date.now()
              }
            }"
          />
        </el-form-item>
      </el-form>
    </template>
    <div style="margin-bottom: 12px">
      <el-button v-auth="'insert'" @click="toInsert()">新 增</el-button>
      <el-button v-auth="'import'" @click="toImport()">导 入</el-button>
      <el-button v-auth="'export'" @click="toExport()">导 出</el-button>
    </div>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="masterSn"
          label="产品序列号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="masterName"
          label="产品名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="partsTypeStr"
          label="配件类型"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="fittingSn"
          label="配件序列号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="fittingCode"
          label="配件料号"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="fittingName"
          label="配件名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="fittingMode"
          label="配件型号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="warrantyDate"
          label="保修截止日期"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.warrantyDate) }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="outboundDate"
          label="出库日期"
        >
          <template slot-scope="{ row }">
            {{ formatDate(row.outboundDate) }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="200"
        >
          <template slot-scope="{ row }">
            <el-button size="medium" type="text" @click="toUpdate(row)">
              编辑
            </el-button>
            <el-button
              size="medium"
              type="text"
              @click="toUnbind(row)"
              v-show="row.masterSn"
            >
              解绑
            </el-button>
            <el-button size="medium" type="text" @click="toDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <ImportModal
      v-if="showImportModal"
      @updateList="getData"
      :visible.sync="showImportModal"
    />
    <AddModal
      :currentItem="currentItem"
      :partsTypeList="partsTypeList"
      v-if="showUpdateModal"
      :visible.sync="showUpdateModal"
      @updateList="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import ImportModal from './components/ImportModal'
import AddModal from './components/AddModal'
export default {
  components: { ImportModal, AddModal },
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      showImportModal: false,
      showUpdateModal: false,
      params: {
        masterSn: '',
        fittingSn: '',
        fittingCode: '',
        fittingMode: '',
        partsTypeId: '',
        page: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      currentItem: null,
      partsTypeList: []
    }
  },
  created() {
    this.getPartsTypeList()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.time) {
          params.startDate = params.time[0]
          params.endDate = params.time[1]
          params.time = undefined
        } else {
          params.startDate = ''
          params.endDate = ''
        }
        const { records, total } = await this.$api.workOrder.listFittingData(
          params
        )
        this.tableData = records || []
        this.total = total
        this.tableData = (records || []).map(item => {
          const partsTypeStr = this.partsTypeList.find(
            _ => _.id == item.typeId
          )?.name
          return { ...item, partsTypeStr }
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toInsert() {
      this.currentItem = null
      this.showUpdateModal = true
    },
    async toImport() {
      this.showImportModal = true
    },
    async toExport() {
      await this.$api.workOrder.exportFittingData(this.params)
      this.$message.success('请求成功，请在下载中心查看进度')
    },
    async toUpdate(row) {
      this.currentItem = row
      this.showUpdateModal = true
    },
    async toUnbind(row) {
      try {
        await this.$confirm('确定解除绑定吗？', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.workOrder.unbindFittingData({
          id: row.id
        })
        this.getData()
        this.$message.success('解绑成功')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async toDelete(row) {
      try {
        await this.$confirm('确定删除数据吗？', '温馨提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.workOrder.deleteFittingData({
          id: row.id
        })
        this.getData()
        this.$message.success('删除成功')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    formatDate(date) {
      return this.$format.dateFormat(date, 'YYYY-mm-dd')
    },
    async getPartsTypeList() {
      this.partsTypeList = await this.$api.workOrder.getPartsTypeList()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .page-content {
  padding-top: 0px !important;
}
</style>
