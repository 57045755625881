<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="导入"
      :visible.sync="show"
      width="400px"
      v-loading="loading"
    >
      <div>
        <el-upload
          ref="upload"
          drag
          action="null"
          :http-request="uploadSectionFile"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            <a
              download="配件数据管理模板.xlsx"
              href="/admin/excelModel/fitting_data.xlsx"
            >
              下载模板
            </a>
            ,在给定的模板文件中填充数据，然后上传
          </div>
        </el-upload>
      </div>
    </el-dialog>
    <ImportErrorInfo
      v-if="importErrorInfoVisible"
      :visible.sync="importErrorInfoVisible"
      :errorTableList="errorTableList"
    />
  </div>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import ImportErrorInfo from '@/components/ImportErrorInfo'

export default {
  mixins: [dialogCommonParams],
  components: { ImportErrorInfo },
  data() {
    return {
      loading: false,
      file: null,
      importErrorInfoVisible: false,
      errorTableList: []
    }
  },
  created() {},

  methods: {
    // async submitForm() {
    //   this.$refs['form'].validate(async valid => {
    //     if (valid) {
    //       try {
    //         this.loading = true
    //         let params = {}
    //         if (params.attachmentList?.length > 0) {
    //           params.attachment = params.attachmentList[0].url
    //           params.fileName = params.attachmentList[0].name
    //           delete params.attachmentList
    //         }
    //         if (this.isCreated) {
    //           await this.$api.workOrder.addTemplate(params)
    //         } else {
    //           await this.$api.workOrder.editTemplate(params)
    //         }

    //         this.$message.success('操作成功')
    //         this.$emit('updateList')
    //         this.show = false
    //       } catch (err) {
    //         console.log(err)
    //       } finally {
    //         this.loading = false
    //       }
    //     }
    //   })
    // },
    async uploadSectionFile(params) {
      try {
        const { file } = params
        this.loading = true
        let data = new FormData()
        data.append('file', file)
        let { errorList = [] } = await this.$api.common.uploadFile3(data)
        if (errorList && errorList.length) {
          this.importErrorInfoVisible = true
          this.errorTableList = errorList
          this.$refs.upload && this.$refs.upload.clearFiles()
          return
        }
        this.$message.success('上传成功')
        this.$emit('updateList')
        this.show = false
      } catch (err) {
        console.log(111, err)
        this.$refs.upload?.clearFiles()
      } finally {
        this.loading = false
      }
    }
    // async download() {
    //   try {
    //     console.log(333333)
    //     await this.$utils.openDdownloadFileBlobownload('', '配件数据管理模板.xlsx')
    //   } catch (e) {
    //     console.log(e)
    //   } finally {
    //     this.loading = false
    //   }
    // }
  },
  watch: {}
}
</script>

<style lang="less" scoped></style>
